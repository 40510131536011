<!--
 * @Author: lbh
 * @Date: 2022-12-28 11:16:17
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-13 11:45:34
 * @Description: file content
-->
<template>
  <div
    class="memberSystemGraphicText1-box px-memberSystemGraphicText1-box"
    :style="`
    padding-top: ${configs.paddingTop}px;
    padding-right: ${configs.paddingRight}px;
    padding-bottom: ${configs.paddingBottom}px;
    padding-left: ${configs.paddingLeft}px;
    background-color:${configs.backgroundColor};`"
    @click="clickItem('bgImage')"
  >
    <h1
      class="title"
      v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
      :class="`edit_${Xindex}_title`"
    ></h1>
    <h2
      class="desc"
      v-html="$util.rex.getHtml(configs[`${nowL}desc`] || configs.desc)"
      :class="`edit_${Xindex}_desc`"
    ></h2>
    <div class="center_">
      <div class="phone">
        <img
          :src="configs.image"
          :alt="configs.alt"
        />
      </div>
      <div class="icon-box">
        <div class="icon-list">
          <div
            class="icon_item"
            v-for="it_,in_ in configs.items"
            :key="in_"
            :style="in_ % 5 == 0 ? 'margin-left: 0px;' : ''"
          >
            <el-image
              style="width: 100%;"
              :src="it_.icon"
            ></el-image>
            <div
              v-html="$util.rex.getHtml(it_[`${nowL}title`] || it_.title)"
              class="it_title"
            ></div>
          </div>
        </div>
        <div class="QR-box">
          <div
            v-for="item,index in configs.qrcodes"
            :key="index"
            class="QR-item"
            :class="`btn_${index}`"
          >
            <img :src="item.icon"></img>
            <selfButton class="RQ_title">
              {{item[`${nowL}title`] || item.title}}
            </selfButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
export default {
  name: "self-member-system-graphic-text1",
  mixins: [langMixin],
  props: {
    Xindex: {
      default () {
        return '';
      },
    },
    configs: {
      default () {
        return {
        }
      }
    }
  },
  created () {
    this.isEdit = this.$store.getters.getIsEdit;
  },
  data () {
    return {
      isEdit: false
    }
  },
  methods: {
    clickItem (e, x) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x);
      }
    },
    getR (index) {
      let len = this.configs.items.length;
      // l 310  
      // R 50
      let max = 100;
      let p = max / (len - 1);
      let t = 310 + (index * p);
      if (t > 360) t = Math.abs(t - 360)
      return t;
    }
  }

}
</script>

<style lang="less" scoped>
.memberSystemGraphicText1-box {
  padding: 60px 0 60px !important;
  .title {
    font-size: 30px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    text-align: center;
  }
  .desc {
    font-size: 20px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    text-align: center;
    margin-top: 6px;
  }
  .center_ {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    .phone {
      flex: 1;
      width: 396px;
      max-width: 396px;
      img {
        width: 100%;
      }
    }
    .icon-box {
      width: 50vw;
      height: 100%;
      margin-left: 96px;
      display: flex;
      flex-direction: column;
      .icon-list {
        display: flex;
        flex-wrap: wrap;
        .icon_item {
          display: flex;
          flex-direction: column;
          margin-left: 60px;
          margin-top: 45px;
          width: calc((100% - 240px) / 5);
          align-items: center;
        }
        .it_title {
          margin-top: 20px;
          font-size: 24px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          white-space: pre;
          text-align: center;
        }
      }
      .QR-box {
        margin-top: 58px;
        display: flex;
        align-items: center;
        .btn_1 {
          margin-left: 40px;
        }
        .QR-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 200px !important;
          }
          .RQ_title {
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
            margin-top: 15px;
            padding: 9px 30px;
            height: auto;
            line-height: inherit;
          }
        }
      }
    }
  }

  & /deep/ .self-button-box {
    cursor: default;
  }
}

@media screen and(max-width:1000px) {
  .px-memberSystemGraphicText1-box {
    .title {
      font-size: 16px !important;
    }
    .desc {
      font-size: 12px !important;
    }
    .icon-box {
      .icon-list {
        .icon_item {
          .it_title {
            font-size: 12px;
          }
        }
      }
    }
  }
}

@media screen and(max-width:500px) {
  .px-memberSystemGraphicText1-box {
    padding: 25px 18px !important;
    .title {
      font-size: 15px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
    }
    .desc {
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }
    .center_ {
      margin-top: 18px;
      min-width: 100%;
      widows: 1005;
      .phone {
        width: 24vw;
        max-width: 24vw;
        min-width: 24vw;
      }
      .icon-box {
        width: auto;
        margin-left: auto;
        .icon-list {
          .icon_item {
            margin-left: 6px;
            margin-top: 11px;
            width: calc((100% - 24px) / 5);
            .el-image {
              width: 18px !important;
            }
            .it_title {
              font-size: 10px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #000000;
              margin-top: 4px;
            }
          }
        }
      }
      .QR-box {
        margin-top: 17px !important;
        margin-left: 10px;
        .QR-item {
          width: auto !important;
          align-items: center;
          .self-button-box {
            font-size: 10px !important;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            height: 20px;
            line-height: 20px;
            margin-top: 5px;
          }
          &.btn_1 {
            margin-left: 15px;
          }
        }
      }
    }
  }
}
</style>